<template>
  <div>
    <div
      class="bg-white mt-2 p-3 mx-10 flex justify-between rounded-lg shadow-lg"
    >
      <h3 class="text-rose text-lg">{{ $t('title_blood_pressure') }}</h3>
      <BackFa :text="$t('back')" />
    </div>
    <div
      id="chart-section"
      class="m-2 w-3/4 mx-auto rounded-2xl overflow-hidden shadow-lg bg-white relative"
    >
      <canvas
        id="lineChart"
        ref="lineChart"
        aria-label="Line Chart"
        role="img"
      ></canvas>
    </div>
  </div>
</template>

<script>
import BackFa from '../components/back/BackFa.vue'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)
import dayjs from './../assets/js/dayjs'

export default {
  name: 'BloodPressure',
  components: { BackFa },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    '$i18n.locale'() {
      this.init()
    },
  },
  computed: {
    day() {
      const days = []
      for (let i = 8; i > 0; i--) {
        days.push(this.convertDate(dayjs().subtract(i, 'day')))
      }
      return days
    },
    items() {
      return {
        type: 'line',
        // locally registered and available for this chart
        // plugins: [dataLabels],
        data: {
          labels: this.day,
          datasets: [
            {
              label: 'PAS',
              data: [125, 145, 130, 150, 110, 140, 135, 158],
              fill: false,
              tension: 0.5,
              borderColor: 'red',
              backgroundColor: 'red',
            },
            {
              label: 'PAD',
              data: [80, 90, 70, 60, 100, 110, 80, 120],
              fill: false,
              tension: 0.5,
              borderColor: 'blue',
              backgroundColor: 'blue',
            },
            {
              label: 'BMP',
              data: [20, 40, 35, 56, 50, 15, 70, 62],
              fill: false,
              tension: 0.5,
              borderColor: 'yellow',
              backgroundColor: 'yellow',
            },
          ],
        },
        options: {
          plugins: {},
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          stacked: false,
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
            },
          },
        },
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.destroy()
      const ctx = document.getElementById('lineChart')
      this.chart = new Chart(ctx || this.$refs.lineChart, this.items)
    },
    destroy() {
      if (this.chart) {
        this.chart.destroy()
      }
    },
  },
}
</script>

<style></style>
